import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ItemListing from '../components/item-listing'
import Card from '../components/card'
import styled from 'styled-components'
import HeroArea from '../components/hero-area'
import CalloutBar from '../components/callout-bar'
import EstimateForm from '../components/estimate-form'

const MainContentContainer = styled.div`
  h1 {
    margin-bottom: 0.25em;
  }
`

const SidebarContainer = styled.div``
const Spacer = styled.div`
  height: 5px;
`

class PageTemplate extends Component {
  render() {
    const pageData = this.props.data.contentfulPage

    const showContactForm = this.props.location.pathname.includes('contact')

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => (
        <Card key={index} sidebar={sidebar} />
      ))

      return sideBarCards
    }

    return (
      <Layout pageProps={this.props}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          robots={pageData.metaRobotsContent}
        />

        {pageData.heroArea ? <HeroArea heroContent={pageData.heroArea} /> : ''}
        {pageData.calloutBar ? (
          <CalloutBar calloutContent={pageData.calloutBar} />
        ) : (
          ''
        )}

        {pageData && pageData.showReputationawardsSection ? (
          <section className="customfeaturedbar">
            <div className="container">
              <div className="row">
                <MainContentContainer
                  className={
                    pageData.sidebar
                      ? 'col-12 is-service-page'
                      : 'col-12 is-service-page'
                  }
                >
                  {pageData &&
                  pageData.featuredContent &&
                  pageData.featuredContent.featuredContent ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData.featuredContent.featuredContent,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </MainContentContainer>
              </div>
            </div>
          </section>
        ) : null}

        <div className="container brand-padding-y">
          <div className="row">
            <MainContentContainer
              className={
                pageData.sidebar
                  ? 'col-12 col-md-8 contained-page-x'
                  : 'col-12 contained-page-x'
              }
            >
              {/* <h1>{pageData.pageTitle}</h1> */}

              {pageData && pageData.body && pageData.body.body ? (
                <div dangerouslySetInnerHTML={{ __html: pageData.body.body }} />
              ) : (
                ''
              )}

              {showContactForm ? (
                <EstimateForm
                  showServicesList={true}
                  showLabels={true}
                  pageLocation={
                    this.props.location.href
                      ? this.props.location.href
                      : 'Not provided'
                  }
                />
              ) : (
                ''
              )}

              {pageData.itemListing ? (
                <ItemListing itemList={pageData.itemListing} />
              ) : (
                ''
              )}
            </MainContentContainer>

            {pageData.sidebar ? (
              <SidebarContainer className="col-12 col-md-4">
                <SidebarColumn />
              </SidebarContainer>
            ) : (
              ''
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      pageTitle
      metaTitle
      metaDescription
      slug
      itemListing {
        id
        serviceTitle
        excerpt
        slug
        heroImage {
          gatsbyImageData(width: 370)
        }
      }

      heroArea {
        id
        name
        heroTitle
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 700)
        }

        primaryActionBtnLabel
        primaryActionBtnLink
      }
      showReputationawardsSection
      featuredContent {
        featuredContent
      }
      body {
        body
      }
      calloutBar {
        id
        title
        description
        backgroundColor
        textColor
      }
      sidebar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
    }
  }
`
